export interface Log
{
    id: number;
    applicationId: string;
    username: string;
    type: string;
    status: string;
    message: string;
    createdAt: Date;
}

export interface Stat
{
    count: string;
    type: string;
    status: string;
}

export const logTypes = {
    login: '#3e6827',
    logout: '#843838',
    mfa: '#1e2d5a',
    ccms: '#40279a',
    graph: '#dc7878',
    database: '#6d6161',
    msal: '#33977b',
    account: '#4c48d9',
    gbo: '#6e2b8b'
};

export const logStatus = {
    success: '#52BE80',
    fail: '#F4D03F',
    error: '#EC7063',
    completed: '#5DADE2'
};
