
import {defineComponent, PropType, reactive, toRefs} from 'vue';
import VueApexCharts from 'vue3-apexcharts';

interface Context {
    chartOptions: {
        labels?: string[];
        colors?: string[];
        tooltip?: { custom?: (obj: any) => string, enabledOnSeries?: string[] };
    }
}

export default defineComponent({
    name: 'TPChart',
    components: {apexchart: VueApexCharts},
    props: {
        series: {
            type: Array,
            required: true
        },
        labels: {
            type: Array as PropType<string[]>,
            required: true
        },
        colors: {
            type: Array as PropType<string[]>,
            required: false
        },
        tooltips: {
            type: Array as PropType<{[key: string]: number; }[]>,
            required: false
        },
        type: {
            type: String,
            required: true,
            validator: (value: string) => ['pie', 'donut', 'radialBar', 'bar'].indexOf(value) !== -1
        },
        title: {
            type: String,
            required: false
        }
    },
    setup (props)
    {
        const context: Context = reactive({
            chartOptions: {
                labels: props.labels,
                colors: props.colors,
                legend: {
                    position: 'top',
                    show: true
                },
                plotOptions: {
                    bar: {
                        columnWidth: '30%',
                        distributed: true,
                    }
                },
                chart: { height: 200},
                tooltip: {}
            }
        });

        if (props.tooltips)
            context.chartOptions.tooltip = { custom: (obj): string =>
            {
                const stat = props.tooltips![obj.seriesIndex];
                const background = props.colors? props.colors[obj.seriesIndex] : '#780096';
                let tooltip = `<div class="p-3" style="background-color: ${background}"> <ul>`;
                Object.keys(stat)
                    .forEach(key => { tooltip += `<li class="is-capitalized">${key}: ${stat[key]}</li>`; });
                tooltip += '</ul> </div>';
                return tooltip;
            }};

        if (props.type === 'bar')
            context.chartOptions.tooltip = { enabledOnSeries: [] };

        return {...toRefs(context)};
    }
});
