
import {defineComponent, onMounted, reactive, toRefs} from 'vue';
import identity from '@/api/identity';
import {toast} from 'bulma-toast';
import {Log, logStatus, Stat} from '@/types/Log';
import {Field, Form, ErrorMessage} from 'vee-validate';
import TPChart from '@/components/TPChart.vue';
import {ApplicationLog} from '@/types/Application';


interface statsOptions {
    series: number[];
    labels: string[];
    colors: string[];
    tooltips?: {[key: string]: number; }[];
}

interface appsOptions {
    series: {data: number[]}[];
    labels: string[];
    colors: string[];
}

interface Context {
    loadingLogs: boolean;
    logs: Log[];
    error: boolean;
    allowed: boolean;
    createdAt: {
        gte: string;
        lte: string;
    };
    chartStats: statsOptions;
    chartApps: appsOptions;
    stats: Stat[];
    topApps: ApplicationLog[];
}

export default defineComponent({
    name: 'Stats',
    components: {Field, Form, ErrorMessage, TPChart},
    setup ()
    {   
        const context: Context = reactive({
            loadingLogs: false,
            logs: [],
            error: false,
            allowed: true,
            createdAt: {
                gte: '',
                lte: ''
            },
            chartStats: {
                series: [],
                labels: Object.keys(logStatus),
                colors: Object.values(logStatus),
                tooltips: []
            },
            chartApps: {
                series: [{data:[]}],
                labels: [],
                colors: []
            },
            stats: [],
            topApps: []
        });

        function setChartStats ()
        {
            const series = {};
            Object.keys(logStatus)
                .forEach(key =>
                {
                    Reflect.set(series, key, {total: 0});
                });
            context.stats.forEach((obj: Stat) =>
            {
                const objStat = Reflect.get(series, obj.status);
                objStat.total += parseInt(obj.count);
                Reflect.set(objStat, obj.type, parseInt(obj.count));
                Reflect.set(series, obj.status, objStat);
            });

            Object.keys(series)
                .forEach(key =>
                {
                    const objStat = Reflect.get(series, key);
                    context.chartStats.series.push(objStat.total);
                    Reflect.deleteProperty(objStat, 'total');
                    context.chartStats.tooltips?.push(objStat);
                });
        }

        function setChartTopApps ()
        {
            context.topApps.forEach(app =>
            {
                context.chartApps.labels.push(app.name);
                context.chartApps.colors.push(`#${app.color}`);
                context.chartApps.series[0].data.push(parseInt(app.count));
            });
        }

        async function getStats ()
        {
            context.loadingLogs = true;
            context.chartStats.tooltips = [];
            context.chartStats.series = [];
            context.chartApps.labels = [];
            context.chartApps.colors = [];
            context.chartApps.series = [{data: []}];
            context.stats = [];
            context.topApps = [];
            try
            {
                const query = `createdAt[lte]=${context.createdAt.lte}&createdAt[gte]=${context.createdAt.gte}`;
                const response = (await  identity.getStatsLogs(query)).data;
                context.stats = response.stats;
                context.topApps = response.topApplications;
                setChartStats();
                setChartTopApps();
            }
            catch (e)
            {
                if(e.response && e.response.status === 403) context.allowed = false;
                else
                {
                    toast({
                        message: 'Error loading logs',
                        type: 'is-danger',
                        duration: 5000,
                        dismissible: true,
                        pauseOnHover: true,
                        position: 'bottom-right'
                    });
                }
            }
            context.loadingLogs = false;
        }

        function maxDate (value: string)
        {
            if (value < context.createdAt.gte)
                return 'To date has to be greater than From date';
            return true;
        }

        onMounted(() =>
        {
            const today = new Date();
            const newDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            context.createdAt.gte = firstDay.toISOString()
                .slice(0, 10);
            context.createdAt.lte = newDate.toISOString()
                .slice(0, 10);
        });

        return {...toRefs(context), getStats, maxDate};
    }
});
